export const FirebaseConfig = {
	"projectId": "knowing-enlightenment",
	"appId": "1:621323428509:web:da31ec7a8c72d414b86a74",
	"databaseURL": "https://knowing-enlightenment-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "knowing-enlightenment.appspot.com",
	"locationId": "asia-south1",
	"apiKey": "AIzaSyAY4gRrLuCkmOW5jD3Pw1bALxG-dr1xUr8",
	"authDomain": "knowing-enlightenment.firebaseapp.com",
	"messagingSenderId": "621323428509",
	"measurementId": "G-BT1LR06MSD"
};